<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIFileUpload from '@/core/shared/components/ui/UIFileUpload';
import FileHelper from '@/core/shared/helpers/FileHelper';

@Component({
  props: {
    banner: {
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  components: {
    UIFileUpload,
    UIIcon,
  },
})
export default class BannerUpload extends Vue {
  imageUploadPreview = null
  get image () {
    return (this.banner && this.banner.imageUrl) || this.imageUploadPreview;
  }

  async onFilesChange ([file]) {
    const image = await FileHelper.encodeImage(file);
    this.imageUploadPreview = image;
    this.$emit('update:banner', {
      ...this.banner,
      _blob: file,
      blobFieldName: `${this.banner.type}${this.banner.size}${this.banner.position}${this.banner.side}`,
      imageUrl: image,
    });
  }

  removeImage () {
    this.imageUploadPreview = null;
    this.$emit('delete', this.banner);
  }
}
</script>

<template lang="pug">

div.banner-upload.flex.flex-column(v-if="!image")
  span.f4.silver
    slot
  UIFileUpload.imguploader.flex-auto.w-100.h0(accept="image/*" rules="image" @change="onFilesChange", :disabled="disabled", :disabled-msg="$t('You can’t upload images until the experience has been created. Retry after saving.')")
div.banner-upload.flex.flex-column(v-else)
  span.f4.silver
    slot
  div.bgimg.contain.bg-center.bg-light-gray.flex-auto.w-100.br3(:style="{backgroundImage: `url('${image}')`}")
  div.flex.items-center.pt2
    span.filename.black.f4.overflow-hidden {{ banner.fileName }}
    button.removebtn.tc.ml-auto(type="button", @click="removeImage")
      UIIcon.w2(name="trash")

</template>

<style scoped>
@import "core/shared/styles";

.banner-upload {
  & >>> .upload-button {
    max-width: none !important;
  }
  & .removebtn {
    @apply --buttonTinyPrimary;
    margin: 0;
    margin-left: auto;
    & svg {
      margin-right: 0;
    }
  }
  & .filename {
    text-overflow: ellipsis;
  }
}
</style>
