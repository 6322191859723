var render, staticRenderFns
import script from "./UIRadioButton.vue?vue&type=script&lang=js&"
export * from "./UIRadioButton.vue?vue&type=script&lang=js&"
import style0 from "./UIRadioButton.vue?vue&type=style&index=0&id=3d3521bf&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../../../.yarn/$$virtual/vue-loader-virtual-2fffd51942/0/cache/vue-loader-npm-15.9.3-4ca89c7e4b-bc7d4c63e0.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d3521bf",
  null
  
)

export default component.exports