<template>
  <ValidationObserver tag="div" ref="validationObserver" class="experiences-confirmation-text">
    <div class="editblock">
      <ContentBlockGroup>
        <ContentBlock>
          <div slot="instructions">
            <h5 class="captiontitle">{{ $t('Booking Confirmation Text') }}</h5>
              <p class="captiondesc">{{ $t('This text will be added to your confirmation emails. To style your text, or add a hyperlink, the following HTML-tags are allowed: &lt;b&gt; &lt;i&gt; &lt;u&gt; &lt;a&gt;. All other tags will be removed.') }}</p>
          </div>
          <div class="body-content">
            <template v-if="localExperience">
              <UIInput
                v-model="localExperience.bookingConfirmationText"
                textarea
                height="30rem" />
            </template>
          </div>
        </ContentBlock>
      </ContentBlockGroup>
    </div>
  </ValidationObserver>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ContentBlock from '@/core/shared/components/ContentBlock';
import CommonMixin from './CommonMixin';
import UIInput from '@/core/shared/components/ui/UIInput';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import ContentBlockGroup from '@/core/shared/components/ContentBlockGroup';
import { ValidationObserver } from 'vee-validate';

@Component({
  mixins: [CommonMixin],
  components: {
    ContentBlock,
    ContentBlockGroup,
    UIInput,
    UIIcon,
    ValidationObserver,
  },
})
export default class ExperiencesShowConfirmationText extends Vue {
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

</style>
