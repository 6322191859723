<template lang="pug">
  ValidationObserver.experiences-refunds(tag="div" ref="validationObserver")
    .editblock
      ContentBlockGroup
        content-block
          div(slot='instructions')
            h5.captiontitle {{ $t('Cancellations & refunds') }}
            p.captiondesc {{ $t('The amount of hours before a reservation that a customer is able to cancel their reservation and receive a full refund. After this point they will not be able to cancel online and will have to contact you.') }}
          .body-content
            template(v-if="localExperience")
              UIInput(
                :title="$t('Cancellation Time')"
                type='number'
                min="0"
                rules="numeric|minValue:0"
                placeholder='hours'
                v-model="localExperience.cancelHours"
                @focus="$event.target.select()"
              )
        content-block
          div(slot='instructions')
            h5.captiontitle {{ $t('Refund Policy') }}
            i18n.captiondesc(tag="p", path="This will be added to your confirmation emails. Use the tag {0} in the template to have your cancellation time display in hours automatically. You can also remove this text and write your own Refund Policy.")
              em.dib.bg-light-gray.b--black-10.ba.br2.pa1.f5 {hours}
          .body-content
            template(v-if="localExperience")
              UIInput(
                :title="$t('Refund Policy')"
                textarea
                v-model="localExperience.refundPolicy"
              )
        content-block
          div(slot='instructions')
            h5.captiontitle {{ $t('Late Policy') }}
            p.captiondesc {{ $t('If you have a Late Policy add it here. This will be added to your confirmation emails.') }}
          .body-content
            template(v-if="localExperience")
              UIInput(
                :title="$t('Late Policy')"
                textarea
                v-model="localExperience.latePolicy"
              )
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ContentBlock from '@/core/shared/components/ContentBlock';
import AdPlacementForm from '../shared/AdPlacementForm';
import CommonMixin from './CommonMixin';
import UIInput from '@/core/shared/components/ui/UIInput';
import ContentBlockGroup from '@/core/shared/components/ContentBlockGroup';
import { ValidationObserver } from 'vee-validate';

@Component({
  mixins: [CommonMixin],
  components: {
    ContentBlock,
    ContentBlockGroup,
    UIInput,
    AdPlacementForm,
    ValidationObserver,
  },
})
export default class ExperiencesShowRefunds extends Vue {}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.experiences-refunds {
}
</style>
