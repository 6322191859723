<template lang="pug">
ValidationObserver.experiences-details(tag="div" ref="validationObserver")
    div.overlay.flex.flex-column.items-center.justify-center.fixed.bg-white.absolute--fill.z-max.pa6(v-if="overlayAds")
      h1.title {{ $t('Ad Preview') }}
      AdPreview.h-100.w-100(:banners="bannersAds")
      button.closebtn.absolute.right-0.top-0.ma4(type="button", @click="closePreviewAds")
        UIIcon.fill-black( name="x")
    .editblock(v-if="localExperience")
      ContentBlockGroup
        content-block()
          div(slot='instructions')
            h5.captiontitle {{ $t('Experience Type') }}
            p.captiondesc {{ $t('Select the type of experience') }}
          div.formitem
            ValidationProvider(:detectInput="false" rules="required" v-slot="{ errors }" :name="$t('Type')" ref="typeValidationProvider")
              UIFormItem(:title="$t('Type')" required :error="!!errors[0]" :errorMsg="errors[0]")
                div.equipment-select
                  UIRadioButton.flex.items-center.justify-center(v-for="type in orderedExperienceTypes" :key="type.key" name='equipment', :value="type.key", v-model="localExperience.type" @input="typeInput" :disabled="localExperience.id")
                    | {{ type.value }}
        content-block
          div(slot='instructions')
            h5.captiontitle {{ $t('Experience Name') }}
            p.captiondesc {{ $t('This will be the title of the Experience for reservation purposes and will display within the Station Monitor.') }}
          div.formitem
            UIInput.w-100(
              v-model="localExperience.title"
              :title="$t('Name')"
              :placeholder="$t('Name of the experience')"
              required
              rules="required|alphaSpacesNumericRich"
            )
        content-block()
          div(slot='instructions')
            h5.captiontitle {{ $t('Description') }}
            p.captiondesc {{ $t('Write a brief description of the Experience the customer will be receiving.') }}
          div.formitem
            UIInput.w-100(
              v-model="localExperience.description"
              :title="$t('Description')"
              :placeholder="$t('Description of the experience')"
              :maxlength="180"
              textarea
            )
        content-block(v-if="localExperience.type === 'vr'")
          div(slot='instructions')
            h5.captiontitle {{ $t('Equipment') }}
            p.captiondesc {{ $t('Select the type of equipment used for this Experience.') }}
          div.formitem
            ValidationProvider(:detectInput="false" :rules="localExperience.type === 'vr' ? 'required' : ''" v-slot="{ errors }" :name="$t('Equipment')" ref="typeValidationProvider")
              UIFormItem(:title="$t('Equipment')" required :error="!!errors[0]" :errorMsg="errors[0]")
                div.equipment-select
                  UIRadioButton.flex.items-center.justify-center(v-for="type in getOrderedEquipmentTypes" :key="type.key" name='equipment', :value="type.key", v-model="localExperience.equipment" @input="typeInput")
                    | {{ type.value }}
        content-block(:required="true")
          div(slot='instructions')
            h5.captiontitle {{ $t('Image') }}
            p.captiondesc {{ $t('This will be the image shown to your customer when reserving this Experience.') }}
          div.coverimage-form
            div.w-80-m
              UIFormItem(:title="$t('Upload your own')")
                div.imagegrid.onecolumn
                  UIRadioThumbnail.coverimgselected.dib.w-100.h-100(v-if="localExperience.imageUrl || experienceDefaultImage", name='image', :value='localExperience.imageUrl || experienceDefaultImage', @input="clearBlobUrl", v-model="localExperience.imageUrl", :style="{ backgroundImage: `url(${localExperience.imageUrl || experienceDefaultImage})`}")
                  div
                    UIFileUpload(@change="imageChange")
                    small.f-instructions {{ $t('16:9 ratio recommended') }}

            div.select-image
              UIFormItem(:title="$t('or select an image')")
                div.imagegrid
                  UIRadioThumbnail(
                    v-for="(image, index) in defaultImagesList",
                    :key="index",
                    name='image',
                    :value='image',
                    v-model="localExperience.blobUrl",
                    @input="clearImageUrl",
                    :style="{ backgroundImage: `url(${image})`}"
                  )
      ContentBlockGroup(v-if="!$features['expanded-experience-settings']")
        content-block
          div(slot='instructions')
            h5.captiontitle {{ $t('Launcher Settings') }}
            p.captiondesc {{ $t('Select the SpringboardVR environment for this Experience.') }}
          div.imagegrid
            UIFormItem(:root="true" :title="launcher.title" v-for="launcher in launchers", :key="launcher.id")
              UIRadioThumbnail.launcherthumbnail(name='launcher' :value='launcher.id' v-model="localExperience.launcher.id" :style="{backgroundImage: `url(${launcher.imageUrl})`}")
        content-block(v-if="!isOrganizationEducation")
          div(slot='instructions')
            h5.captiontitle {{ $t('Launcher Home Screen Content Layout') }}
            p.captiondesc
              i18n(path='Pick which categories you want players to see in your Content List home screen in the SpringboardVR environment. Go to {content} to pick in which order your titles will be displayed within Difficulties, Tags or if you turn all the toggles off.')
                template(#content)
                  UIButton(link :to="{ name: 'content-library' }") {{ $t('My Library') }}
          div.launcher-settings.mt3
            UISwitch(
              v-model="localExperience.launcherTrending"
              :title="$t('Trending Titles')"
              :instructions="$t('The trending titles at your location.')"
            )
            UISwitch(
              v-model="localExperience.launcherRecentlyAdded"
              :title="$t('New Releases')"
              :instructions="$t('The newest titles you’ve added to your location.')"
            )
            UISwitch(
              v-model="localExperience.launcherDifficulty"
              :title="$t('Difficulties')"
              :instructions="$t('Easy, Medium and Hard categories will be displayed on your home screen. If off, players will still be able to filter by difficulty.')"
            )
            UISwitch(
              v-model="localExperience.launcherGenre"
              :title="$t('Tags')"
              :instructions="$t('All the tags will be displayed on your home screen. If off, players will still be able to filter by tag.')"
            )

        content-block()
          div(slot='instructions')
            h5.captiontitle {{ $t('Launcher Billboards') }}
            p.captiondesc {{ $t('Your logo will appear in the SpringboardVR environment.') }}
          .body-content
            div.billboards
              div.overflow-hidden
                label.label {{ $t('Landscape') }}
                BannerUpload.h5(:banner="bannersBySize.landscape[0]", @update:banner="updateBanner", @delete="deleteBanner")
                  small.f-instructions {{ $t('Recommended 390px x 167px') }}
              div.overflow-hidden
                label.label {{ $t('Portrait') }}
                BannerUpload.h5(:banner="bannersBySize.portrait[0]", @update:banner="updateBanner", @delete="deleteBanner")
                  small.f-instructions {{ $t('Recommended 240px x 630px') }}
              div.overflow-hidden
                label.label {{ $t('Square') }}
                BannerUpload.h5(:banner="bannersBySize.square[0]", @update:banner="updateBanner", @delete="deleteBanner")
                  small.f-instructions {{ $t('Recommended 300px x 300px') }}
        content-block()
          div(slot='instructions')
            h5.captiontitle {{ $t('Launcher Ads') }}
            p.captiondesc {{ $t('These ads will appear in the SpringboardVR environment.') }}
          .body-content(v-if="!loading")
            div.ads.flex-m
              AdPlacementForm.formitem.mr4.flex-auto.w-50-m(:get-default-banner="getDefaultBanner", :banners.sync="bannersLeft", side="left")
              AdPlacementForm.formitem.flex-auto.w-50-m(:get-default-banner="getDefaultBanner", :banners.sync="bannersRight", side="right")
            button.previewbtn.center.db(type="button", @click="previewAds") {{ $t('Preview Ads') }}
        content-block()
          div(slot='instructions')
            h5.captiontitle {{ $t('Content Ratings') }}
            p.captiondesc {{ $t('Content Ratings help SpringboardVR with sharing more accurate data and feedback to content creators about their titles and to location operators about trending content around the world.') }}
          .body-content
            UISwitch(
              v-model="localExperience.gameReviewsEnabled"
              :title="$t('Content Ratings')"
              :instructions="$t('If on, players will be asked to rate the title (good or bad) when they are done playing.')"
            )
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ContentBlock from '@/core/shared/components/ContentBlock';
import ContentBlockGroup from '@/core/shared/components/ContentBlockGroup';
import AdPlacementForm from '../shared/AdPlacementForm';
import CommonMixin from './CommonMixin';
import { groupBy } from 'lodash-es';
import AdPreview from '../shared/AdPreview';
import BannerUpload from '../shared/BannerUpload';
import UISwitch from '@/core/shared/components/ui/UISwitch';
import UIInput from '@/core/shared/components/ui/UIInput';
import UIRadioButton from '@/core/shared/components/ui/UIRadioButton';
import UIRadioThumbnail from '@/core/shared/components/ui/UIRadioThumbnail';
import UISelect from '@/core/shared/components/ui/UISelect';
import UIFormItem from '@/core/shared/components/ui/UIFormItem';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIFileUpload from '@/core/shared/components/ui/UIFileUpload';
import { generateId } from '@/core/shared/helpers/VariousHelper';
import FileHelper from '@/core/shared/helpers/FileHelper';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

@Component({
  mixins: [CommonMixin],
  components: {
    ContentBlock,
    ContentBlockGroup,
    AdPlacementForm,
    UIRadioButton,
    AdPreview,
    UIFileUpload,
    BannerUpload,
    UISwitch,
    UIInput,
    UISelect,
    UIRadioThumbnail,
    UIFormItem,
    UIButton,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    launchers: {
      required: true,
    },
    isOrganizationEducation: {
      type: Boolean,
      required: true,
    },
  },
})
export default class ExperiencesShowDetails extends Vue {
  overlayAds = false
  experienceDefaultImage = null
  experienceTypes = []
  equipmentTypes = []
  defaultImages = {
    vr: [
      'https://storage.googleapis.com/api-storage.springboardvr.com/assets/experiences/AdminPanel-Experience-Girl.jpg',
      'https://storage.googleapis.com/api-storage.springboardvr.com/assets/experiences/AdminPanel-Experience-Man01.jpg',
      'https://storage.googleapis.com/api-storage.springboardvr.com/assets/experiences/AdminPanel-Experience-Man02.jpg',
      'https://storage.googleapis.com/api-storage.springboardvr.com/assets/experiences/AdminPanel-Experience-Woman01.jpg',
      'https://storage.googleapis.com/api-storage.springboardvr.com/assets/experiences/AdminPanel-Experience-Woman02.jpg',
    ],
    'non-vr': [
      'https://storage.googleapis.com/api-storage.springboardvr.com/assets/experiences/AdminPanel-Experience-axe-trowhing.jpg',
      'https://storage.googleapis.com/api-storage.springboardvr.com/assets/experiences/AdminPanel-Experience-bowling.jpg',
      'https://storage.googleapis.com/api-storage.springboardvr.com/assets/experiences/AdminPanel-Experience-business-room.jpg',
      'https://storage.googleapis.com/api-storage.springboardvr.com/assets/experiences/AdminPanel-Experience-dart.jpg',
      'https://storage.googleapis.com/api-storage.springboardvr.com/assets/experiences/AdminPanel-Experience-escape-room.jpg',
      'https://storage.googleapis.com/api-storage.springboardvr.com/assets/experiences/AdminPanel-Experience-paintball.jpg',
    ],
    kiosk: [
      'https://storage.googleapis.com/api-storage.springboardvr.com/assets/experiences/AdminPanel-Experience-Girl.jpg',
    ],
  }

  get defaultImagesList () {
    return this.defaultImages[this.localExperience.type];
  }

  get getOrderedEquipmentTypes () {
    if (this.equipmentTypes.length === 0) {
      return [];
    }
    const otherOption = this.equipmentTypes.find(type => { return type.value === 'Other'; });
    const filteredTypes = this.equipmentTypes.filter(type => {
      return type.value !== 'Other';
    }).sort((a, b) => {
      if (a.value < b.value) { return -1; }
      if (a.value > b.value) { return 1; }
      return 0;
    });
    filteredTypes.push(otherOption);
    return filteredTypes;
  }

  get orderedExperienceTypes () {
    return [
      {
        value: 'Custom Experience',
        key: 'non-vr',
      },
      {
        value: 'VR Experience',
        key: 'vr',
      },
    ];
  }

  get isNew () {
    return !this.experience.id;
  }

  get origin () {
    return window.location.origin;
  }

  typeInput (value) {
    this.$refs.typeValidationProvider.syncValue(value);
  }

  async imageChange ([file]) {
    const imageUrl = await FileHelper.encodeImage(file);
    this.$set(this.localExperience, '_blob', file);
    this.$set(this.localExperience, 'blobFieldName', 'image');
    this.$set(this.localExperience, 'imageUrl', imageUrl);
  }

  clearImageUrl () {
    this.experienceDefaultImage = this.experienceDefaultImage || this.localExperience.imageUrl;
    this.localExperience.imageUrl = null;
  }

  clearBlobUrl () {
    this.localExperience.blobUrl = null;
  }

  get banners () {
    return this.localExperience.banners;
  }

  get bannersBySide () {
    return groupBy(this.bannersAds, 'side');
  }

  get bannersBySize () {
    const byTypes = groupBy(this.bannersBillboards, 'size'); // `landscape` `portrait` `square`
    const byTypesWithDefaults = {
      landscape: [
        this.getDefaultBanner({ type: 'launcher', size: 'landscape' }),
      ],
      portrait: [this.getDefaultBanner({ type: 'launcher', size: 'portrait' })],
      square: [this.getDefaultBanner({ type: 'launcher', size: 'square' })],
      ...byTypes,
    };
    return byTypesWithDefaults;
  }

  get bannersBillboards () {
    const banners = this.banners.filter(b => b.type === 'launcher');
    return banners;
  }

  get bannersAds () {
    const banners = this.banners.filter(b => b.type === 'station');
    return banners;
  }

  get bannersLeft () {
    const banners = this.bannersBySide.left || [this.getDefaultBanner({ type: 'station', side: 'left', size: 'full' })];
    return banners;
  }

  set bannersLeft (banners) {
    const otherBanners = this.localExperience.banners.filter(({ type, side }) => !(type === 'station' && side === 'left'));
    this.localExperience.banners = [...banners, ...otherBanners];
  }

  get bannersRight () {
    const banners = this.bannersBySide.right || [this.getDefaultBanner({ type: 'station', side: 'right', size: 'full' })];
    return banners;
  }

  set bannersRight (banners) {
    const otherBanners = this.localExperience.banners.filter(({ type, side }) => !(type === 'station' && side === 'right'));
    this.localExperience.banners = [...banners, ...otherBanners];
  }

  getDefaultBanner ({ type, side, size }) {
    return {
      _id: generateId(),
      position: null,
      size,
      type,
      side,
      imageUrl: null,
    };
  }

  updateBanner (banner) {
    const { id, _id } = banner;
    const bannerIndex = this.localExperience.banners.findIndex(banner => (!_id ? banner.id === id : banner._id === _id));
    if (~bannerIndex) {
      this.$set(this.localExperience.banners, bannerIndex, { ...this.localExperience.banners[bannerIndex], ...banner });
    } else {
      this.localExperience.banners.push(banner);
    }
  }

  deleteBanner (banner) {
    const { id, _id } = banner;
    const bannerIndex = this.localExperience.banners.findIndex(banner => (!_id ? banner.id === id : banner._id === _id));
    this.$delete(this.localExperience.banners, bannerIndex);
  }

  previewAds () {
    this.overlayAds = true;
  }

  closePreviewAds () {
    this.overlayAds = false;
  }

  async created () {
    const [{ experienceTypes }] = await Promise.all([this.$store.dispatch('getExperienceTypes')]);

    this.equipmentTypes = [...experienceTypes];
  }

  mounted () {
    // NOTE(Jack): Since the content block doesn't get rendered until loading is false, then we have to watch the variable to get the proper ref
    if (this.loading && !this.$refs.typeValidationProvider) {
      const unwatchLoading = this.$watch(() => this.loading, value => {
        if (!value && this.$refs.typeValidationProvider) {
          this.$refs.typeValidationProvider.syncValue(this.localExperience.type);
          unwatchLoading();
        }
      });
    } else {
      this.$refs.typeValidationProvider.syncValue(this.localExperience.type);
    }

    // this.$watch(() => this.localExperience.isVrExperience, (value) => {
    //   debugger;
    //   if (!value) {
    //     this.localExperience.type = 'other';
    //     // this.$refs.equipmentValidationProvider.syncValue(null);
    //   }
    // });
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.experiences-details {
  /* padding-top: var(--spacingMd); */

  & .body-content {
    @apply --f5;
  }
  & .label {
    @apply --f8;
  }
  & .equipment-select > * {
    @media (--tablet) {
      padding: 0;
    }
    @media (--desktopXL) {
      padding: var(--spacingSm);
    }
  }
  & .coverimage-form {
    display: grid;
    grid-gap: var(--spacingMd);
    /* grid-template-columns: 1fr; */
    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  & .select-image {
    @media (--tablet) {
      grid-column: 2 / 4;
    }
  }
  & .coverimgselected {
    height: 120px;
  }
  & .imagegrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--spacingSm);
    /* grid-auto-rows:  */
    grid-auto-rows: minmax(120px, max-content);
    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  & .launcherthumbnail {
    height: 120px;
  }
  & .onecolumn {
    grid-template-columns: 1fr;
  }
  & .equipment-select {
    display: grid;
    grid-gap: 1rem;
    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: var(--spacingSm);
    }
    /* grid-auto-rows: 120px; */
  }
  & .billboards {
    display: grid;
    grid-gap: var(--spacingSm);
    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: var(--spacingMd);
    }
    /* grid-auto-rows: 120px; */
  }
  & .launcher-settings {
    display: grid;
    grid-gap: var(--spacingSm);
    @media (--tablet) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: var(--spacingMd);
    }
  }
  & .previewbtn {
    @apply --buttonSecondary;
  }
  & .closebtn {
    @apply --buttonTinyPrimary;
    & >>> .icon {
      width: 3.6rem;
      height: 3.6rem;
    }
  }
  & .title {
    @apply --f2;
  }
  & .cover {
    background-position: 0;
  }
}
</style>
