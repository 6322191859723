<template>
<div class="content-block-group">
  <slot/>
</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  provide () {
    return {
      contentBlockGroup: true,
    };
  },
})
export default class ContentBlockGroup extends Vue {

}
</script>

<style scoped lang="postcss">
@import "../styles";

.content-block-group {
  & >>> .content-block {
    margin-bottom: 0;
    & + .content-block .panel {
      border-radius: 0;
      &:before {
        content: "";
        border-top: 1px solid var(--colorGeyser);
        width: calc(100% - 2 * var(--spacingMd));
        /* width: calc(100% - 2 * var(-spacingSm)); */
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        z-index: 2;
        height: 10px;
      }
      @media(--tablet) {
        &:before {
          display: none;
        }
      }
    }
    &:first-child .panel {
      border-radius: 0;
      border-top-left-radius: var(--radius);
      border-top-right-radius: var(--radius);
    }
    &:last-child .panel {
      border-bottom-left-radius: var(--radius);
      border-bottom-right-radius: var(--radius);
    }
  }
  margin-bottom: var(--spacingLg);
}
</style>
