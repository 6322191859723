<template>
  <div class="experiences-index">
    <div class="container">
      <header class="actions">
        <UIButton class="newbtn" :to="{ name: 'experiences-new' }">{{ $t('New Experience') }}</UIButton>
      </header>
      <UIGridIndexLoader v-if="isLoading" />
      <div class="grid animated fadeIn" v-if="!isLoading">
        <ExperienceCard
          class="experiencecard"
          v-for="experience in experiences"
          :key="experience.id"
          :experience="experience"
          :experienceTypes="experienceTypes"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import ExperienceCard from './shared/ExperienceCard';
import Component from 'vue-class-component';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIGridIndexLoader from '@/core/shared/components/ui/UIGridIndexLoader';

@Component({
  props: {
    locationId: {
      type: String,
      required: true,
    },
  },
  components: {
    UIButton,
    UIGridIndexLoader,
    ExperienceCard: {
      functional: true,
      render (h, ctx) {
        return h('router-link', {
          props: {
            to: { name: 'experiences-show', params: { id: ctx.props.experience.id } },
          },
        }, [h(ExperienceCard, ctx.data)]);
      },
    },
  },
})
export default class ExperiencesIndex extends Vue {
  loading = true;
  experienceTypes = [];

  get isLoading () {
    return this.loading && this.experiences.length === 0;
  }

  get experiences () {
    return this.$store.getters['entities/Experience/all']().filter(experience => experience.location ? experience.location.id === this.locationId : false);
  }

  async created () {
    await this.$store.dispatch('getLocationExperiences', this.locationId);

    const { experienceTypes } = await this.$store.dispatch('getExperienceTypes');
    this.experienceTypes = experienceTypes;

    this.loading = false;
    this.$store.commit('setTitle', this.$t('Experiences'));
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.experiences-index {
  padding-bottom: 7rem;
  & .container {
    @apply --container;
  }
  & .grid {
    padding-top: var(--spacingSm);
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--spacingMd);
    justify-content: center;

    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  & .actions {
    margin: var(--spacingSm) 0;
    text-align: right;
    @media (--desktop) {
      margin: var(--spacingMd) 0;
    }
  }
  & .experiencecard {
    cursor: pointer;
    min-height: 27rem;
  }
  & .newbtn {
    @apply --buttonPrimary;
    grid-area: b;
    justify-self: flex-end;
  }
  & .typeselect {
    @apply --select;
    grid-area: a;
  }
}
</style>
