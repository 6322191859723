<script>
import Vue from 'vue';
import UIButton from '@/core/shared/components/ui/UIButton';

import Component from 'vue-class-component';
import moment from 'moment';

@Component({
  props: {
    hour: {
      required: true,
    },
    twentyFourHourTime: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UIButton,
  },
})
export default class ExperienceHourItem extends Vue {
  get daysToDisplay () {
    const {
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
    } = this.hour;
    const days = {
      monday: {
        label: moment.weekdaysMin(1),
        value: monday,
      },
      tuesday: {
        label: moment.weekdaysMin(2),
        value: tuesday,
      },
      wednesday: {
        label: moment.weekdaysMin(3),
        value: wednesday,
      },
      thursday: {
        label: moment.weekdaysMin(4),
        value: thursday,
      },
      friday: {
        label: moment.weekdaysMin(5),
        value: friday,
      },
      saturday: {
        label: moment.weekdaysMin(6),
        value: saturday,
      },
      sunday: {
        label: moment.weekdaysMin(7),
        value: sunday,
      },
    };
    return Object.keys(days).filter(k => days[k].value).map(k => days[k].label).join(', ');
  }

  get openingTime () {
    return this.hour.open ? moment(this.hour.open, 'HH:mm:ss').format(this.timeDisplayFormat) : '-';
  }

  get closingTime () {
    return this.hour.close ? moment(this.hour.close, 'HH:mm:ss').format(this.timeDisplayFormat) : '-';
  }

  get timeDisplayFormat () {
    return this.twentyFourHourTime ? 'H:mm' : 'h:mm a';
  }
}
</script>

<template lang="pug">
tr.experience-hour-item.relative
  //- HourPicker.absolute.z-5.h-auto
  td.daycell.tl
    span {{ daysToDisplay }}
  td.openingtimecell.tl
    span {{ openingTime }}
  td.closingtimecell.tl
    span {{ closingTime }}
  td.tr.buttons
    UIButton.editbtn.tc.mr3(tiny secondary icon="edit", @click="$emit('edit', hour)")
    UIButton.removebtn.tc(tiny icon="trash", @click="$emit('delete', hour)")

</template>

<style scoped lang="postcss">
@import "core/shared/styles";

.experience-hour-item {
  border-bottom: 1px solid var(--colorGeyser);
  &:last-child {
    border-bottom: none;
  }
  & .daycell {
    @media (--tablet) {
      width: 40%;
    }
  }
  & .removebtn {
    display: inline-flex;
    padding-right: 0;
  }
  & .editbtn {
    display: inline-flex;
  }
  & .hourpicker {
    max-width: 50rem;
  }
  & .buttons {
    width: 8rem;
  }
}
</style>
