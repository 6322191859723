<template>
  <ValidationObserver tag="div" ref="validationObserver" class="experience-launcher-settings">
    <div
      class="overlay flex flex-column items-center justify-center fixed bg-white absolute--fill z-max pa6"
      v-if="overlayAds"
    >
      <h1 class="title">{{ $t('Announcements Preview') }}</h1>
      <AdPreview class="h-100 w-100" :banners="bannersAds"></AdPreview>
      <button class="closebtn absolute right-0 top-0 ma4" type="button" @click="closePreviewAds">
        <UIIcon class="fill-black" name="x"></UIIcon>
      </button>
    </div>
    <ContentBlockGroup>
      <ContentBlock>
        <div slot="instructions">
          <h5 class="captiontitle">{{ $t('Background Environment') }}</h5>
          <p class="captiondesc">
            {{ $t('Select the SpringboardVR environment for this Experience.') }}
            <span
              class="fw6"
            >{{ $t('The changes will apply after you restart the launcher.') }}</span>
          </p>
        </div>
        <div class="imagegrid">
          <UIFormItem
            :root="true"
            :title="launcher.title"
            v-for="launcher in launchers"
            :key="launcher.id"
          >
            <UIRadioThumbnail
              class="launcherthumbnail"
              name="launcher"
              :value="launcher.id"
              v-model="localExperience.launcher.id"
              :style="{backgroundImage: `url(${launcher.imageUrl})`}"
            ></UIRadioThumbnail>
          </UIFormItem>
        </div>
      </ContentBlock>
    </ContentBlockGroup>
    <ContentBlockGroup>
      <ContentBlock v-if="!isOrganizationEducation">
        <div slot="instructions">
          <h5 class="captiontitle">{{ $t('Launcher Home Screen Content Layout') }}</h5>
          <p class="captiondesc">
            <i18n
              path="Pick which categories you want players to see in your Content List home screen in the SpringboardVR environment. Go to {content} to pick in which order your titles will be displayed within Difficulties, Tags or if you turn all the toggles off."
            >
              <template #content>
                <UIButton link="link" :to="{ name: 'content-library' }">{{ $t('My Library') }}</UIButton>
              </template>
            </i18n>
          </p>
        </div>
        <div class="launcher-settings mt3">
          <UISwitch
            v-model="localExperience.launcherTrending"
            :title="$t('Trending Titles')"
            :instructions="$t('The trending titles at your location.')"
          ></UISwitch>
          <UISwitch
            v-model="localExperience.launcherRecentlyAdded"
            :title="$t('New Releases')"
            :instructions="$t('The newest titles you’ve added to your location.')"
          ></UISwitch>
          <UISwitch
            v-model="localExperience.launcherDifficulty"
            :title="$t('Difficulties')"
            :instructions="$t('Easy, Medium and Hard categories will be displayed on your home screen. If off, players will still be able to filter by difficulty.')"
          ></UISwitch>
          <UISwitch
            v-model="localExperience.launcherGenre"
            :title="$t('Tags')"
            :instructions="$t('All the tags will be displayed on your home screen. If off, players will still be able to filter by tag.')"
          ></UISwitch>
        </div>
      </ContentBlock>
      <ContentBlock>
        <div slot="instructions">
          <h5 class="captiontitle">{{ $t('Launcher Billboards') }}</h5>
          <p class="captiondesc">{{ $t('Your logo will appear in the SpringboardVR environment.') }}</p>
        </div>
        <div class="body-content">
          <div class="billboards">
            <div class="overflow-hidden">
              <label class="label">{{ $t('Landscape') }}</label>
              <BannerUpload
                class="h5"
                :banner="bannersBySize.landscape[0]"
                @update:banner="updateBanner"
                @delete="deleteBanner"
              >
                <small class="f-instructions">{{ $t('Recommended 390px x 167px') }}</small>
              </BannerUpload>
            </div>
            <div class="overflow-hidden">
              <label class="label">{{ $t('Portrait') }}</label>
              <BannerUpload
                class="h5"
                :banner="bannersBySize.portrait[0]"
                @update:banner="updateBanner"
                @delete="deleteBanner"
              >
                <small class="f-instructions">{{ $t('Recommended 240px x 630px') }}</small>
              </BannerUpload>
            </div>
            <div class="overflow-hidden">
              <label class="label">{{ $t('Square') }}</label>
              <BannerUpload
                class="h5"
                :banner="bannersBySize.square[0]"
                @update:banner="updateBanner"
                @delete="deleteBanner"
              >
                <small class="f-instructions">{{ $t('Recommended 300px x 300px') }}</small>
              </BannerUpload>
            </div>
          </div>
        </div>
      </ContentBlock>
      <ContentBlock>
        <div slot="instructions">
          <h5 class="captiontitle">{{ $t('Launcher Announcements') }}</h5>
          <p
            class="captiondesc"
          >{{ $t('You can display your own announcements or promotions in the launcher.') }}</p>
        </div>
        <div class="body-content" v-if="!loading">
          <div class="ads flex-m">
            <AdPlacementForm
              class="formitem mr4 flex-auto w-50-m"
              :get-default-banner="getDefaultBanner"
              :banners.sync="bannersLeft"
              side="left"
            ></AdPlacementForm>
            <AdPlacementForm
              class="formitem flex-auto w-50-m"
              :get-default-banner="getDefaultBanner"
              :banners.sync="bannersRight"
              side="right"
            ></AdPlacementForm>
          </div>
          <button
            class="previewbtn center db mt3"
            type="button"
            @click="previewAds"
          >{{ $t('Preview Announcements') }}</button>
        </div>
      </ContentBlock>
    </ContentBlockGroup>
    <ContentBlockGroup>
      <ContentBlock>
        <div slot="instructions">
          <h5 class="captiontitle">{{ $t('Content Ratings') }}</h5>
          <p
            class="captiondesc"
          >{{ $t('Content Ratings help SpringboardVR with sharing more accurate data and feedback to content creators about their titles and to location operators about trending content around the world.') }}</p>
        </div>
        <div class="body-content">
          <UISwitch
            v-model="localExperience.gameReviewsEnabled"
            :title="$t('Content Ratings')"
            :instructions="$t('If on, players will be asked to rate the title (good or bad) when they are done playing.')"
          ></UISwitch>
        </div>
      </ContentBlock>
    </ContentBlockGroup>
  </ValidationObserver>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { groupBy } from 'lodash-es';
import { ValidationObserver } from 'vee-validate';

import CommonMixin from './CommonMixin';
import { generateId } from '@/core/shared/helpers/VariousHelper';

import ContentBlock from '@/core/shared/components/ContentBlock';
import ContentBlockGroup from '@/core/shared/components/ContentBlockGroup';
import AdPlacementForm from '../shared/AdPlacementForm';
import AdPreview from '../shared/AdPreview';
import BannerUpload from '../shared/BannerUpload';
import UISwitch from '@/core/shared/components/ui/UISwitch';
import UIRadioButton from '@/core/shared/components/ui/UIRadioButton';
import UIRadioThumbnail from '@/core/shared/components/ui/UIRadioThumbnail';
import UISelect from '@/core/shared/components/ui/UISelect';
import UIFormItem from '@/core/shared/components/ui/UIFormItem';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIFileUpload from '@/core/shared/components/ui/UIFileUpload';

@Component({
  mixins: [CommonMixin],
  components: {
    ContentBlock,
    ContentBlockGroup,
    AdPlacementForm,
    UIRadioButton,
    AdPreview,
    UIFileUpload,
    BannerUpload,
    UISwitch,
    UISelect,
    UIRadioThumbnail,
    UIFormItem,
    UIButton,
    ValidationObserver,
  },
  props: {
    launchers: {
      required: true,
    },
    isOrganizationEducation: {
      type: Boolean,
      required: true,
    },
  },
})
export default class ExperienceShowLauncherSettings extends Vue {
  overlayAds = false;
  skylandLauncherId = '3ef40fb-9e4d-48c4-8d9b-507de7e012bc';

  get skylandLauncher () {
    return this.launchers.find(({ id }) => id === this.skylandLauncherId);
  }

  get banners () {
    return this.localExperience.banners;
  }

  get bannersBySide () {
    return groupBy(this.bannersAds, 'side');
  }

  get bannersBySize () {
    const byTypes = groupBy(this.bannersBillboards, 'size'); // `landscape` `portrait` `square`
    const byTypesWithDefaults = {
      landscape: [
        this.getDefaultBanner({ type: 'launcher', size: 'landscape' }),
      ],
      portrait: [this.getDefaultBanner({ type: 'launcher', size: 'portrait' })],
      square: [this.getDefaultBanner({ type: 'launcher', size: 'square' })],
      ...byTypes,
    };
    return byTypesWithDefaults;
  }

  get bannersBillboards () {
    const banners = this.banners.filter(b => b.type === 'launcher');
    return banners;
  }

  get bannersAds () {
    const banners = this.banners.filter(b => b.type === 'station');
    return banners;
  }

  get bannersLeft () {
    const banners = this.bannersBySide.left || [this.getDefaultBanner({ type: 'station', side: 'left', size: 'full' })];
    return banners;
  }

  set bannersLeft (banners) {
    const otherBanners = this.localExperience.banners.filter(({ type, side }) => !(type === 'station' && side === 'left'));
    this.localExperience.banners = [...banners, ...otherBanners];
  }

  get bannersRight () {
    const banners = this.bannersBySide.right || [this.getDefaultBanner({ type: 'station', side: 'right', size: 'full' })];
    return banners;
  }

  set bannersRight (banners) {
    const otherBanners = this.localExperience.banners.filter(({ type, side }) => !(type === 'station' && side === 'right'));
    this.localExperience.banners = [...banners, ...otherBanners];
  }

  getDefaultBanner ({ type, side, size }) {
    return {
      _id: generateId(),
      position: null,
      size,
      type,
      side,
      imageUrl: null,
    };
  }

  updateBanner (banner) {
    const { id, _id } = banner;
    const bannerIndex = this.localExperience.banners.findIndex(banner => (!_id ? banner.id === id : banner._id === _id));
    if (~bannerIndex) {
      this.$set(this.localExperience.banners, bannerIndex, { ...this.localExperience.banners[bannerIndex], ...banner });
    } else {
      this.localExperience.banners.push(banner);
    }
  }

  deleteBanner (banner) {
    const { id, _id } = banner;
    const bannerIndex = this.localExperience.banners.findIndex(banner => (!_id ? banner.id === id : banner._id === _id));
    this.$delete(this.localExperience.banners, bannerIndex);
  }

  previewAds () {
    this.overlayAds = true;
  }

  closePreviewAds () {
    this.overlayAds = false;
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.experience-launcher-settings {
  & .body-content {
    @apply --f5;
  }
  & .label {
    @apply --f8;
  }
  & .imagegrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--spacingSm);
    /* grid-auto-rows:  */
    grid-auto-rows: minmax(120px, max-content);
    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  & .skylandoptionsgrid {
    display: grid;

    @media (--tablet) {
      grid-template-columns: 1fr 1fr;
    }
  }
  & .launcherthumbnail {
    height: 120px;
  }
  & .onecolumn {
    grid-template-columns: 1fr;
  }
  & .equipment-select {
    display: grid;
    grid-gap: 1rem;
    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: var(--spacingSm);
    }
    /* grid-auto-rows: 120px; */
  }
  & .billboards {
    display: grid;
    grid-gap: var(--spacingSm);
    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: var(--spacingMd);
    }
    /* grid-auto-rows: 120px; */
  }
  & .launcher-settings {
    display: grid;
    grid-gap: var(--spacingSm);
    @media (--tablet) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: var(--spacingMd);
    }
  }
  & .previewbtn {
    @apply --buttonSecondary;
  }
  & .closebtn {
    @apply --buttonTinyPrimary;
    & >>> .icon {
      width: 3.6rem;
      height: 3.6rem;
    }
  }
  & .title {
    @apply --f2;
  }
  & .cover {
    background-position: 0;
  }
}
</style>
