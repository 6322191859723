import UIIcon from '@/core/shared/components/ui/UIIcon';

import { cloneDeep, isEqual } from 'lodash-es';

export default {
  props: {
    experience: {
      required: true,
    },
    loading: {},
  },
  components: {
    UIIcon,
  },
  data: () => ({
    localExperience: null,
    validationObserver: null,
  }),
  created () {
    this.$watch(
      () => this.experience,
      value => {
        this.localExperience = value && cloneDeep(value);
      },
      { immediate: true },
    );
    this.$watch(
      () => this.localExperience,
      value => {
        if (!isEqual(value, this.experience)) {
          if (this.validationObserver) {
            this.validationObserver.validateWithInfo({ silent: true }).then(({ isValid, errors }) => {
              this.$emit('update:experience', this.localExperience, this.$options.name, isValid);
            });
          } else {
            this.$emit('update:experience', this.localExperience);
          }
        }
      },
      { deep: true },
    );
  },
  mounted () {
    this.validationObserver = this.$refs.validationObserver;
  },
};
