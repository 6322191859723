<script>
import Vue from 'vue';
import BannerUpload from './BannerUpload';
import Component from 'vue-class-component';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UISelect from '@/core/shared/components/ui/UISelect';

import { invert, orderBy } from 'lodash-es';

const adLayoutMap = {
  full: 1,
  half: 2,
};
const positions = ['top', 'bottom'];

@Component({
  props: {
    side: { required: true },
    banners: { required: true },
    disabled: {},
    getDefaultBanner: { required: true },
  },
  components: {
    BannerUpload,
    UISelect,
    UIIcon,
  },
})
export default class AdPlacementForm extends Vue {
  get layout () {
    return invert(adLayoutMap)[this.banners.length];
  }

  set layout (size) {
    const bannersLength = adLayoutMap[size];
    const newBanners = Array(bannersLength)
      .fill(this.banners[0])
      .map((banner, i) => ({ ...banner, size, position: size === 'full' ? null : positions[i], id: null, imageUrl: null }));
    this.$emit('update:banners', newBanners);
  }

  get bannersOrdered () {
    return orderBy(
      this.banners,
      ({ position }) => {
        return position === 'top' ? 1 : 0;
      },
      ['desc'],
    );
  }

  deleteBanner (index) {
    const newBanners = [...this.banners];
    const banner = this.banners[index];
    this.$set(newBanners, index, { ...banner, id: undefined, imageUrl: null, _blob: null, fileName: null });
    this.$emit('update:banners', newBanners);
  }

  updateBanner (index, banner) {
    const newBanners = [...this.banners];
    this.$set(newBanners, index, banner);
    this.$emit('update:banners', newBanners);
  }
}
</script>

<template lang="pug">
.bannerad-placement-form
  UISelect.select.mb4(
    v-model="layout"
    :title="side === 'left' ? $t('Left side') : $t('Right side')"
  )
    option(value="half") {{ $t('Two Half Sized Announcements') }}
    option(value="full") {{ $t('Full Size Announcements') }}
  div.uploaders.flex.flex-column
    div.flex-auto.h0(v-for="(banner, i) in bannersOrdered", :key="banner.id || i", :class="banners.length > 1 && i !== (banners.length - 1) ? 'mb4' : ''")
      div.h-100.flex.flex-column
        BannerUpload.imguploader.flex-auto.w-100.h0(@delete="deleteBanner(i)", :banner="banner", @update:banner="updateBanner(i, arguments[0])", :disabled="disabled")
          span.f-instructions.pt2.pb4 {{ layout === 'half' ? $t('Recommended: 400 x 380px') : $t('Recommended: 400 x 800px')}}

</template>

<style scoped lang="postcss">
@import "core/shared/styles";

.bannerad-placement-form {
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  & >>> .upload-button {
    max-width: none !important;
  }
  & .removebtn {
    @apply --buttonTinyPrimary;
    margin: 0;
    margin-left: auto;
    & svg {
      margin-right: 0;
    }
  }
  & .uploaders {
    height: 38rem;
  }
}
</style>
