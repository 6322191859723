<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UIIcon from '@/core/shared/components/ui/UIIcon';

@Component({
  props: {
    experience: {
      type: Object,
      required: true,
    },
    experienceTypes: {
      type: Array,
      required: true,
    },
  },
  components: { UIIcon },
})
export default class ExperienceCard extends Vue {
  get technology () {
    if (this.experienceTypes.length > 0) {
      const technology = this.experienceTypes.find(type => type.key === this.experience.type);
      return technology?.value;
    }
  }

  get image () {
    if (this.experience && this.experience.id) {
      return this.experience.imageUrl ? `url(${this.experience.imageUrl})` : '';
    }
  }
}
</script>

<template>
  <div class="experience-card" :style="{ backgroundImage: image }">
    <header class="infos tc">
      <h1 class="title">
        {{ experience.title }}
        <small class="technology" v-if="technology">{{ technology }}</small>
      </h1>
    </header>
  </div>
</template>

<style lang="postcss" scoped>
@import "core/shared/styles";

.experience-card {
  @apply --panelDropShadow;
  border-radius: var(--radius);
  position: relative;
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  height: 0;
  overflow: hidden;
  color: black;

  & .infos {
    padding: var(--spacingMd);
    display: flex;
    align-items: center;
    background-color: white;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  & .title {
    @apply --f3;
    margin: 0;
    flex: 1 1 auto;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  & .technology {
    @apply --f6;
    color: var(--colorManatee);
    margin: 0;
    display: block;
  }
  & .techlogo {
    width: 30%;
  }
}
</style>
