<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import ContentBlock from '@/core/shared/components/ContentBlock';
import ExperienceTierItem from '../shared/ExperienceTierItem';
import ExperienceEducationTierItem from '../shared/ExperienceEducationTierItem';
import CommonMixin from './CommonMixin';
import ExperienceHourItem from '../shared/ExperienceHourItem';
import ExperienceCustomHourItem from '../shared/ExperienceCustomHourItem';
import HourPicker from '../shared/HourPicker';
import CustomHourPicker from '../shared/CustomHourPicker';
import Modal from '@/core/shared/components/Modal';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIInput from '@/core/shared/components/ui/UIInput';
import { ValidationObserver } from 'vee-validate';

@Component({
  props: {
    isOrganizationEducation: {
      type: Boolean,
      default: false,
    },
    typeOfStation: {
      default: 'station',
    },
  },
  mixins: [CommonMixin],
  components: {
    ContentBlock,
    ExperienceTierItem,
    ExperienceEducationTierItem,
    ExperienceHourItem,
    ExperienceCustomHourItem,
    Modal,
    UIInput,
    UIButton,
    HourPicker,
    CustomHourPicker,
    ValidationObserver,
  },
})
export default class ExperiencesShowPricing extends Vue {
  hourBeingEdited = null;
  customHourBeingEdited = null;

  get location () {
    return this.$store.getters['entities/Location'](this.localExperience.location.id);
  }

  // Hours
  get hours () {
    return this.localExperience.hours.map((v, i) => ({ ...v, _index: i })).filter(({ _delete }) => !_delete);
  }

  addHour () {
    this.hourBeingEdited = {};
  }

  confirmHourEdition (hour) {
    if (hour.id || '_index' in hour) {
      this.updateHour(hour);
    } else {
      this.localExperience.hours.push(hour);
    }
    this.hourBeingEdited = null;
  }

  updateHour (hour) {
    this.$set(this.localExperience.hours, hour._index, hour);
  }

  deleteHour (hour) {
    if (hour.id) {
      this.$set(this.localExperience.hours, hour._index, { ...hour, _delete: true });
    } else {
      this.$delete(this.localExperience.hours, hour._index);
    }
  }

  showHourPicker (hour) {
    this.hourBeingEdited = hour;
  }

  // Custom Hours
  get customHours () {
    return this.localExperience.customHours.map((v, i) => ({ ...v, _index: i })).filter(({ _delete }) => !_delete);
  }

  addCustomHour () {
    this.customHourBeingEdited = {
      open: true,
    };
  }

  confirmCustomHourEdition (customHour) {
    if (customHour.id || '_index' in customHour) {
      this.updateCustomHour(customHour);
    } else {
      this.localExperience.customHours.push(customHour);
    }
    this.customHourBeingEdited = null;
  }

  updateCustomHour (customHour) {
    this.$set(this.localExperience.customHours, customHour._index, customHour);
  }

  deleteCustomHour (customHour) {
    if (customHour.id) {
      this.$set(this.localExperience.customHours, customHour._index, { ...customHour, _delete: true });
    } else {
      this.$delete(this.localExperience.customHours, customHour._index);
    }
  }

  showCustomHourPicker (customHour) {
    this.customHourBeingEdited = customHour;
  }

  // Tiers
  get tiers () {
    return this.localExperience.tiers.map((v, i) => ({ ...v, _index: i })).filter(({ _delete }) => !_delete);
  }

  addTier () {
    this.localExperience.tiers.push({ price: 0, length: 0 });
  }

  updateTier (tier) {
    this.$set(this.localExperience.tiers, tier._index, tier);
  }

  deleteTier (tier) {
    if (tier.id) {
      this.$set(this.localExperience.tiers, tier._index, { ...tier, _delete: true });
    } else {
      this.$delete(this.localExperience.tiers, tier._index);
    }
  }

  // Other
  setHoursToNull () {
    this.hourBeingEdited = null;
    this.customHourBeingEdited = null;
  }
}
</script>

<template>
  <ValidationObserver class="experiences-pricing" tag="div" ref="validationObserver">
    <modal class="share-modal" :prevent-scroll="true" @closeModal="setHoursToNull" v-if="hourBeingEdited || customHourBeingEdited">
      <template v-if="hourBeingEdited">
        <HourPicker :hour.sync="hourBeingEdited" :locale="location.locale"
          :twenty-four-hour-time="location.twentyFourHourTime" @confirm="confirmHourEdition"
          @cancel="hourBeingEdited = null"></HourPicker>
      </template><template v-if="customHourBeingEdited">
        <CustomHourPicker :customHour.sync="customHourBeingEdited" @confirm="confirmCustomHourEdition"
          @cancel="customHourBeingEdited = null" :locale="location.locale"
          :twenty-four-hour-time="location.twentyFourHourTime"></CustomHourPicker>
      </template></modal>
    <div class="editblock" v-if="localExperience">
      <content-block v-if="isOrganizationEducation">
        <div slot="instructions">
          <h5 class="captiontitle">{{ $t('Lengths of Time') }}</h5>
          <p class="captiondesc">{{ $t('These are the lengths of time available for reserving this Experience.') }}</p>
        </div>
        <div class="body-content">
          <ExperienceEducationTierItem class="mb4" :tier="tier" @update:tier="updateTier" @delete="deleteTier"
            v-for="(tier, i) in tiers" :key="tier.id || i"></ExperienceEducationTierItem><button class="addbtn f2"
            type="button" @click="addTier">
            <UIIcon class="w2 mr2" name="plus"></UIIcon>{{ $t('Add length of time') }}
          </button>
        </div>
      </content-block>
      <content-block v-if="!isOrganizationEducation">
        <div slot="instructions">
          <h5 class="captiontitle">{{ $t('Pricing') }}</h5>
          <p class="captiondesc">{{ $t('These are the prices and lenghts of time available for reserving this Experience.') }}</p>
        </div>
        <div class="body-content">
          <ExperienceTierItem class="mb4" :tier="tier" @update:tier="updateTier" @delete="deleteTier"
            v-for="(tier, i) in tiers" :key="tier.id || i"></ExperienceTierItem>
          <UIButton tiny="tiny" icon="plus" @click="addTier">{{ $t('Add price') }}</UIButton>
        </div>
      </content-block>
      <content-block style="z-index: 2">
        <div slot="instructions">
          <h5 class="captiontitle">{{ $t('Available Hours') }}</h5>
          <p class="captiondesc">{{ $t('These are the hours that this specific Experience will be available for reservations via the Widget.') }}</p>
        </div>
        <div class="body-content">
          <table class="w-100 collapse f5 mb4" v-if="hours.length">
            <thead>
              <th class="hourtablehead f-thead tl">{{ $t('Day') }}</th>
              <th class="hourtablehead f-thead tl">{{ $t('Opening time') }}</th>
              <th class="hourtablehead f-thead tl">{{ $t('Closing time') }}</th>
              <th class="hourtablehead f-thead tl"></th>
            </thead>
            <ExperienceHourItem class="z-5" v-for="(hour, i) in hours" :hour="hour"
              :twenty-four-hour-time="location && location.twentyFourHourTime" @edit="showHourPicker"
              @delete="deleteHour" :key="hour.id || i"></ExperienceHourItem>
          </table>
          <UIButton tiny="tiny" icon="plus" @click="addHour">{{ $t('Add hours') }}</UIButton>
        </div>
      </content-block>
      <content-block style="z-index: 2">
        <div slot="instructions">
          <h5 class="captiontitle">{{ $t('Custom Hours') }}</h5>
          <p class="captiondesc">{{ $t('Set hours for specific days of the year when your arcade is closed or has custom hours.Dates and times selected here will override your normal hours of operations.') }}</p>
        </div>
        <div class="body-content"
          :class="{ 'flex items-center justify-center flex-column': customHours && !customHours.length }">
          <div v-if="customHours && !customHours.length">
            <p>{{ $t('There are currently no custom hours set') }}</p>
          </div>
          <table class="w-100 collapse f5 mb4" v-else>
            <thead>
              <tr>
                <th class="hourtablehead f-thead tl">{{ $t('State') }}</th>
                <th class="hourtablehead f-thead tl reason">{{ $t('Reason') }}</th>
                <th class="hourtablehead f-thead tl hideonphone">{{ $t('Date') }}</th>
                <th class="hourtablehead f-thead tl hideonphone">{{ $t('Opening time') }}</th>
                <th class="hourtablehead f-thead tl hideonphone">{{ $t('Closing time') }}</th>
                <th class="hourtablehead f-thead tl"></th>
              </tr>
            </thead>
            <tbody>
              <ExperienceCustomHourItem class="mb4 z-5" v-for="(customHour, i) in customHours" :customHour="customHour"
                :twenty-four-hour-time="location && location.twentyFourHourTime" @edit="showCustomHourPicker"
                @delete="deleteCustomHour" :key="customHour.id || i"></ExperienceCustomHourItem>
            </tbody>
          </table>
          <UIButton :tiny="true" icon="plus" type="button" @click="addCustomHour">{{ $t('Add custom hours') }}</UIButton>
        </div>
      </content-block>
      <content-block>
        <div slot="instructions">
          <h5 class="captiontitle">{{ $t('Options') }}</h5>
          <p class="captiondesc">{{ $t('These options will help customers reserve time via the Widget. Cushion Time keeps customers from reserving back to back with no transition time between.Reservation Increment is the increment of time that is used when making a Reservation via the Widget.We suggest you keep the increments for cushion time and reservation increment the same.') }}</p>
        </div>
        <div class="options">
          <UIInput class="w-100" v-model="localExperience.maxPeoplePerStation" :title="$t(`Max people per ${typeOfStation}`)"
            :instructions="$t(`This is the maximum number of people that can share a ${typeOfStation}.`)" type="number" min="0"
            rules="numeric|minValue:0" @focus="$event.target.select()"></UIInput>
          <UIInput class="w-100 ttc" v-model="localExperience.bookingSetupTime" :title="$t(`${typeOfStation} Setup Time (In Minutes)`)"
            :instructions="$t('The amount of time to be added to a reservation to allow for setup.')" type="number"
            min="0" rules="numeric|minValue:0" @focus="$event.target.select()"></UIInput>
          <UIInput class="w-100" v-model="localExperience.bookingCushionTime"
            :title="$t('Cushion Time Between Reservations (In Minutes)')"
            :instructions="$t('The amount of time required between reservations.')" type="number" min="0"
            rules="numeric|minValue:0" @focus="$event.target.select()"></UIInput>
          <UIInput class="w-100" v-model="localExperience.bookingIncrementTime"
            :title="$t('Reservation Increment (In Minutes)')"
            :instructions="$t('If you pick 15 minutes, then the options will be 1:00, 1:15, 1:30, 1:45 etc.')"
            type="number" min="0" rules="numeric|minValue:0" @focus="$event.target.select()"></UIInput>
          <UIInput class="w-100" v-model="localExperience.bookingMinimumWindowTime"
            :title="$t('Minimum Booking Window (In Minutes)')"
            :instructions="$t('Minimum amount of time between now and the next possible booking')"
            type="number" min="0" rules="numeric|minValue:0" @focus="$event.target.select()"></UIInput>
        </div>
      </content-block>
    </div>
  </ValidationObserver>
</template>

<style scoped lang="postcss">
@import "core/shared/styles";

.experiences-pricing {
  & .options {
    display: grid;

    @media (--tablet) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: var(--spacingSm);
    }

    @media (--desktop) {
      grid-gap: var(--spacingMd);
    }
  }

  & table th {
    padding-right: 1rem;

    @media (--tablet) {
      padding-right: 0;
    }
  }

  & table>>>td {
    padding: var(--spacingSm) 1rem var(--spacingSm) 0;

    @media (--tablet) {
      padding: var(--spacingSm) 0;
    }
  }

  & .addbtn {
    @apply --buttonTinyPrimary;
    font-size: 1.4rem;

    &:hover {
      background-color: transparent;
      border: none;
    }
  }
  & .hideonphone {
    display: none;
    @media (--tablet) {
      display: table-cell;
    }
  }
}
</style>
