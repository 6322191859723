<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { groupBy } from 'lodash-es';

@Component({
  props: {
    banners: {
      required: true,
    },
  },
})
export default class AdPreview extends Vue {
  get gridStyle () {
    const { left: bannersLeft = [], right: bannersRight = [] } = groupBy(this.banners, 'side');
    const makeGrid = (side, length) =>
      length === 1 ? [`${side}-full`, `${side}-full`] : [`${side}-half-top`, `${side}-half-bottom`];
    const grid = [makeGrid('left', bannersLeft.length), makeGrid('right', bannersRight.length)];
    return {
      gridTemplateAreas: `
        "${grid[0][0]} ${grid[1][0]}"
        "${grid[0][1]} ${grid[1][1]}"
      `,
    };
  }

  getGridArea ({ size, side, position }) {
    return [side, size, position].filter(v => !!v).join('-');
  }
};
</script>

<template lang="pug">
  div.bannerad-box(:style="gridStyle")
    div.adimg(v-for="({ imageUrl, size, side, position }) in banners", :style="{ gridArea: getGridArea({ size, side, position}), backgroundImage: `url(${imageUrl})` }")

</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="postcss">
.bannerad-box {
  height: 40vmin;
  width: calc(40vmin + 2vmin);
  display: grid;
  grid-gap: 2vmin;
  & .adimg {
    background-position: center;
    background-size: cover !important;
  }
}
</style>
