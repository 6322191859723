<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UIDatepicker from '@/core/shared/components/ui/UIDatepicker';
import UITimepicker from '@/core/shared/components/ui/UITimepicker';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIRadio from '@/core/shared/components/ui/UIRadio';
import UIInput from '@/core/shared/components/ui/UIInput';
import DateHelper from '@/core/shared/helpers/DateHelper';
import moment from 'moment';

@Component({
  components: {
    UIDatepicker,
    UITimepicker,
    UIButton,
    UIRadio,
    UIInput,
  },
  props: {
    customHour: {
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    twentyFourHourTime: {
      type: Boolean,
      default: false,
    },
  },
})
export default class HourCustomPicker extends Vue {
  localHour = {
    open: true,
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    reason: '',
  }

  created () {
    this.localHour = { ...this.localHour, ...this.customHour };
    this.$watch('localHour', (val, prev) => {
      if (!val.open) {
        val.startTime = null;
        val.endTime = null;
      } else {
        val.startTime = this.formatTime(val.startTime);
        val.endTime = this.formatTime(val.endTime);
      }
      this.$emit('update:customHour', val);
    }, { deep: true });
    this.$watch('customHour', (val, prev) => {
      this.localHour = this.customHour;
    }, { deep: true });
  }

  // ERIK: Can't find where 14:00:00 gets converted to 2:00 PM, so I made this ugly work around
  formatTime (value) {
    if (value === null) {
      return null;
    }
    let result = value;
    if (value.indexOf('M') !== -1) {
      const parts = value.split(/ |:/);
      result = (parseInt(parts[0]) + (parts[2] === 'AM' ? 0 : 12)).toString().padStart(2, '0') + ':' + parts[1] + ':00';
    }
    if (result === '24:00:00') {
      result = '00:00:00'; // prevent overlaping hour bugs in the server
    }
    return result;
  }

  get isValid () {
    const isOpen = this.customHour.open;
    if (isOpen) {
      return !!this.customHour.startDate &&
      !!this.customHour.endDate &&
      !!this.customHour.startTime &&
      !!this.customHour.endTime;
    } else {
      return this.customHour.startDate && this.customHour.endDate;
    }
  }

  validateAndSubmit () {
    if (!this.isDateValid(this.customHour)) {
      this.$store.commit('setFlash', {
        message: `${this.$t('The end date can’t be before the start date.')}`,
        type: 'error',
      });
    } else if (!this.isTimeValid(this.customHour)) {
      this.$store.commit('setFlash', {
        message: `${this.$t('The end time can’t be before the start time.')}`,
        type: 'error',
      });
    } else {
      this.$emit('confirm', this.customHour);
    }
  }

  isDateValid (customHour) {
    return (this.customHour.startDate && this.customHour.endDate) && moment(this.customHour.endDate).isSameOrAfter(this.customHour.startDate);
  }

  isTimeValid (customHour) {
    const startTime = moment(this.customHour.startTime, 'kk:mm:ss');
    const endTime = moment(this.customHour.endTime, 'kk:mm:ss');
    const startDate = moment(this.customHour.startDate).set({
      hours: startTime.get('hour'),
      minutes: startTime.get('minute'),
    });
    const endDate = moment(this.customHour.endDate).set({
      hours: endTime.get('hour'),
      minutes: endTime.get('minute'),
    });
    return endDate.isSameOrAfter(startDate);
  }

  get datesToDisable () {
    return {
      from: '1900-01-01',
      to: DateHelper.getClientNowMoment().subtract(1, 'days').format('YYYY-MM-DD'),
    };
  }
}
</script>

<template lang="pug">

div.custom-experience-hour-item.f4.bg-white.pa4
  div.grid
    div.instructions
      h4.title.f2 {{ $t('Add Custom Hours') }}
      p.f5 {{ $t('Select specific dates that your arcade will be closed or have custom hours.') }}
    div.radios
      UIRadio.openradio(:title="$t('Open')" :value="localHour.open" @change="localHour.open = true")
      UIRadio.openradio(:title="$t('Closed')" :value="!localHour.open" @change="localHour.open = false")
    UIDatepicker(
      :title="$t('Start Date')"
      placeholder="Select a date"
      type="text"
      :locale="locale"
      v-model="localHour.startDate"
      format="YYYY-MM-DD"
      :options="{ disable: [datesToDisable] }"
      required
    )
    UIDatepicker(
      :title="$t('End Date')"
      placeholder="Select a date"
      type="text"
      :locale="locale"
      v-model="localHour.endDate"
      format="YYYY-MM-DD"
      :options="{ disable: [datesToDisable] }"
      required
    )
    template(v-if="localHour.open")
      UITimepicker(
        v-model="localHour.startTime"
        :title="$t('Opening Time')"
        format="kk:mm:ss"
        :twenty-four-hour-time="twentyFourHourTime"
        :locale="locale"
      )
      UITimepicker(
        v-model="localHour.endTime"
        :title="$t('Closing Time')"
        format="kk:mm:ss"
        :twenty-four-hour-time="twentyFourHourTime"
        :locale="locale"
      )
    UIInput.reasoninput(:title="$t('Reason')" :placeholder="$t('Reason')" maxlength="50" v-model="localHour.reason")
    UIButton.cancelbtn(ghost @click="$emit('cancel')") {{ $t('Cancel') }}
    UIButton.confirmbtn(secondary @click="validateAndSubmit" :disabled="!isValid") {{ $t('Done') }}

</template>

<style scoped lang="postcss">
@import "core/shared/styles";

.custom-experience-hour-item {
  & .grid {
    display: grid;
    grid-gap: var(--spacingSm);

    @media (--tablet) {
      grid-template-columns: repeat(2, 1fr);
    }

    & .radios {
      & *:first-child {
        margin-right: var(--spacingSm);
      }
    }

    & .instructions,
    & .radios {
      @media (--tablet) {
        grid-column: span 2;
      }
    }

    & .reasoninput {
      margin-bottom: var(--spacingSm);

      @media (--tablet) {
        grid-column: span 2;
      }
    }
  }
}
</style>
