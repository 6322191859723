<template>
  <div class="ui-grid-index-loader animated fadeIn">
    <UIGradientLoader />
    <UIGradientLoader />
    <UIGradientLoader />
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import UIGradientLoader from './UIGradientLoader';

@Component({
  components: {
    UIGradientLoader,
  },
})
export default class UIGridIndexLoader extends Vue {}
</script>

<style lang="postcss" scoped>
@import '../../styles';

.ui-grid-index-loader {
  display: grid;

  grid-template-columns: 1fr;
  grid-gap: var(--spacingSm);

  @media (--tablet) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--desktop) {
    grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
  }

  & .ui-gradient-loader {
    height: 20rem;
    border-radius: var(--radius);
    & >>> .animation {
      background-size: 160% 100%;
    }
  }
}
</style>
