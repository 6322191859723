<script>
import Vue from 'vue';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIInput from '@/core/shared/components/ui/UIInput';
import UIButton from '@/core/shared/components/ui/UIButton';

import Component from 'vue-class-component';

@Component({
  props: {
    tier: {
      required: true,
    },
  },
  components: {
    UIIcon,
    UIButton,
    UIInput,
  },
})
export default class ExperienceTierItem extends Vue {
  tempPrice = 0;

  // BUG(Jack): There is an issue where the value in the input does not change if entered manually multiple times (e.g. enter 3, gets normalized toFixed(2); enter 3 again and it remains as an integer)
  get price () {
    const parsedTempPrice = parseFloat(this.tempPrice);
    if (Number.isNaN(parsedTempPrice)) {
      return this.tempPrice;
    }
    return parsedTempPrice.toFixed(2);
  }

  set price (value) {
    this.tempPrice = value;
    this.$emit('update:tier', { ...this.tier, price: this.tempPrice && Math.round(this.tempPrice * 100) });
  }

  get length () {
    return this.tier.length;
  }

  set length (value) {
    let trueValue = value;
    // NOTE(Jack): Not sure what changed here, but apparently this can be a string when the input type is number 🤔
    if (typeof value === 'string') {
      trueValue = parseInt(value);
    }
    this.$emit('update:tier', { ...this.tier, length: trueValue });
  }

  mounted () {
    this.tempPrice = this.tier.price && (this.tier.price / 100);
  }
}
</script>

<template>
  <div class="priceandlengthform-item">
    <UIInput class="priceinput input" :title="$t('Price')" type="number" v-model="price" min="0" rules="decimal:2|minValue:0" @focus="$event.target.select()"></UIInput>
    <UIInput class="lengthinput input" :title="`${$t('Length of time')} (${$t('min')})`" type="number" v-model="length" min="0" rules="numeric|minValue:0" @focus="$event.target.select()"></UIInput>
    <UIButton class="removebtn" tiny="tiny" icon="trash" @click="$emit('delete', tier)"></UIButton>
  </div>
</template>

<style scoped lang="postcss">
@import "core/shared/styles";

.priceandlengthform-item {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-gap: var(--spacingSm);
  align-items: flex-end;
  @media (--desktop) {
    grid-gap: var(--spacingMd);
  }
  & .removebtn {
    padding: calc(1.5rem - 0.2rem) 1.5rem;
  }
}
</style>
