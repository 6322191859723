<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment';
import UICheckboxButton from '@/core/shared/components/ui/UICheckboxButton';
import UIFormItem from '@/core/shared/components/ui/UIFormItem';
import UITimepicker from '@/core/shared/components/ui/UITimepicker';
import UIButton from '@/core/shared/components/ui/UIButton';

const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];
@Component({
  props: {
    hour: {
      required: true,
    },
    twentyFourHourTime: {
      type: Boolean,
      default: false,
    },
    locale: {
      required: true,
    },
  },
  components: {
    UICheckboxButton,
    UIButton,
    UIFormItem,
    UITimepicker,
  },
})
export default class HourPicker extends Vue {
  weekDays = moment.weekdaysMin()
  get daysToDisplay () {
    return days.reduce((result, day, i) => ({
      ...result,
      [day]: {
        label: moment.weekdaysMin(i + 1),
        day,
        value: this.hour[day],
      },
    }), {});
  }

  updateDay (day, value) {
    this.$emit('update:hour', {
      ...this.hour,
      [day]: value,
    });
  }

  // ERIK: Can't find where 14:00:00 gets converted to 2:00 PM, so I made this ugly work around
  formatTime (value) {
    if (value === null) {
      return null;
    }
    let result = value;
    if (value.indexOf('M') !== -1) {
      const parts = value.split(/ |:/);
      result = (parseInt(parts[0]) + (parts[2] === 'AM' ? 0 : 12)).toString().padStart(2, '0') + ':' + parts[1] + ':00';
    }
    if (result === '24:00:00') {
      result = '00:00:00'; // prevent overlaping hour bugs in the server
    }
    return result;
  }

  get open () { return this.hour.open; }
  set open (value) {
    value = this.formatTime(value);
    this.$emit('update:hour', {
      ...this.hour,
      open: value,
    });
  }

  get close () { return this.hour.close; }
  set close (value) {
    value = this.formatTime(value);
    this.$emit('update:hour', {
      ...this.hour,
      close: value,
    });
  }

  get isValid () {
    const hasAtLeastOneDay = days.some(day => this.hour[day]);
    return hasAtLeastOneDay && !!this.hour.open && !!this.hour.close;
  }
}
</script>

<template lang="pug">
div.experience-hour-item.f4.bg-white.pa4
  div.grid
    h4.title.f2 {{ $t('Add hours of operation') }}
    .dayselect
      UIFormItem(:title="$t('Day')")
        div.flex
          UICheckboxButton.dayscheckbox.mr2.flex-auto(:value="value", @update-model="updateDay(day, arguments[0])", v-for='({ value, label, day }) in daysToDisplay',:key="label")
            | {{ label }}
    UITimepicker.open(
      v-model="open"
      :title="$t('Opening Time')"
      format="kk:mm:ss"
      :twenty-four-hour-time="twentyFourHourTime"
      :locale="locale"
    )
    UITimepicker.close(
      v-model="close"
      :title="$t('Closing Time')"
      format="kk:mm:ss"
      :twenty-four-hour-time="twentyFourHourTime"
      :locale="locale"
    )
    UIButton.cancelbtn(ghost @click="$emit('cancel')") {{ $t('Cancel') }}
    UIButton.confirmbtn(secondary :disabled="!isValid" @click="$emit('confirm', hour)") {{ $t('Done') }}

</template>

<style scoped lang="postcss">
@import "core/shared/styles";

.experience-hour-item {
  & .grid {
    display: grid;
    grid-gap: var(--spacingSm);
    @media (--tablet) {
      grid-gap: var(--spacingSm);
    }
    grid-template-columns: repeat(2, 1fr);
  }

  & .dayselect {
    grid-column: 1/-1;
  }

  & .dayscheckbox {
    & >>> label {
      padding: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    padding: 0;
  }
}
</style>
