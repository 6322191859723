<script>
import Vue from 'vue';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIInput from '@/core/shared/components/ui/UIInput';
import UIButton from '@/core/shared/components/ui/UIButton';

import Component from 'vue-class-component';

@Component({
  props: {
    tier: {
      required: true,
    },
  },
  components: {
    UIIcon,
    UIButton,
    UIInput,
  },
})
export default class ExperienceEducationTierItem extends Vue {
  get length () {
    return this.tier.length;
  }

  set length (value) {
    this.$emit('update:tier', { ...this.tier, length: value, price: 0 });
  }
}
</script>

<template lang="pug">
div.priceandlengthform-item
  UIInput.lengthinput.input(
    :title="`${$t('Length of time')} (${$t('min')})`"
    type="number"
    v-model="length"
    min="0"
    @focus="$event.target.select()"
    @blur="length = length || 0"
  )
  UIButton.removebtn(tiny icon="trash" @click="$emit('delete', tier)")

</template>

<style scoped lang="postcss">
@import "core/shared/styles";

.priceandlengthform-item {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: var(--spacingSm);
  align-items: flex-end;
  @media (--desktop) {
    grid-gap: var(--spacingMd);
  }
  & .removebtn {
    padding: calc(1.5rem - 0.2rem) 1.5rem;
  }
}
</style>
