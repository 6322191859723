<script>
import Vue from 'vue';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIButton from '@/core/shared/components/ui/UIButton';
import moment from 'moment';
import Component from 'vue-class-component';

@Component({
  components: {
    UIIcon,
    UIButton,
  },
  props: {
    customHour: {
      required: true,
    },
    twentyFourHourTime: {
      type: Boolean,
      default: false,
    },
  },
})
export default class ExperienceCustomHourItem extends Vue {
  get displayDate () {
    const format = 'L';
    const start = moment(this.customHour.startDate).format(format);
    const end = moment(this.customHour.endDate).format(format);
    return `${start} - ${end}`;
  }

  get startTime () {
    return this.prettyFormat(this.customHour.startTime);
  }

  get endTime () {
    return this.prettyFormat(this.customHour.endTime);
  }

  get timeDisplayFormat () {
    return this.twentyFourHourTime ? 'H:mm' : 'h:mm a';
  }

  prettyFormat (time) {
    return (time && moment(time, 'H:mm:ss').format(this.timeDisplayFormat)) || '-';
  }
}
</script>

<template lang="pug">
tr.experience-custom-hour-item.relative
  td.statecell.tl
    span {{ (customHour.open) ? $t('Open') : $t('Closed') }}
  td.reasoncell.tl
    span {{ (customHour.reason) ? customHour.reason : "-" }}
  td.daycell.tl
    span {{ displayDate }}
  td.openingtimecell.tl
    span {{ startTime }}
  td.closingtimecell.tl
    span {{ endTime }}
  td.tr.buttons
    UIButton.editbtn.tc.mr3(tiny secondary icon="edit", @click="$emit('edit', customHour)")
    UIButton.removebtn.tc(tiny icon="trash", @click="$emit('delete', customHour)")

</template>

<style scoped lang="postcss">
@import "core/shared/styles";

.experience-custom-hour-item {
  border-bottom: 1px solid var(--colorGeyser);
  &:last-child {
    border-bottom: none;
  }
  & .buttons {
    min-width: 6.5rem;
  }
  & .reasoncell {
    /* display: none; */
    /* display: block;
    @media (--tablet) {
    } */
  }
  & .openingtimecell,
  & .closingtimecell,
  & .daycell {
    display: none;
    @media (--tablet) {
      display: table-cell;
    }
  }
  & .removebtn {
    display: inline-flex;
    padding-right: 0;
  }
  & .editbtn {
    display: inline-flex;
  }
  & .hourpicker {
    max-width: 50rem;
  }
}
</style>
