<script>
import Component, { mixins } from 'vue-class-component';
import ModelMixin from './ModelMixin';
import UiFormItem from './UIFormItem';

@Component({
  name: 'UICheckboxButton',
  props: UiFormItem.options.props,
  components: {
    UiFormItem,
  },
})
export default class UICheckboxButton extends mixins(ModelMixin) {

}
</script>

<template lang="pug">
UiFormItem(:title="title", :instructions="instructions", :required="required", :root="true")
  label.ui-checkbox-button(:class="{ '-active': internalValue }")
    input.input(v-bind="$attrs", type="checkbox", v-on="$listeners", v-model="internalValue")
    slot
</template>

<style scoped lang="postcss">
@import "../../styles";

.ui-checkbox-button {
  @apply --buttonGhostTemplate;
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease, border 0.3s ease;

  & .input {
    position: absolute;
    left: -30000px;
    visibility: hidden;
    opacity: 0;
  }
  & svg {
    fill: inherit;
  }
  &.-active {
    @apply --buttonSecondary;
    fill: white;
    border: 0.1rem solid var(--colorStorm);
  }
}
</style>
