<script>
import Vue from 'vue';
import UnstyledRadio from './unstyled/UnstyledRadio';
import Component from 'vue-class-component';

@Component({
  name: 'UIRadioButton',
  model: {
    prop: 'model',
  },
  functional: true,
})
export default class UIRadioButton extends Vue {
  render (h, ctx) {
    const { data } = ctx;
    if (ctx.parent.$options._scopeId) {
      data.attrs[ctx.parent.$options._scopeId] = '';
    }
    return h(UnstyledRadio, {
      ...data,
      staticClass: 'ui-radiobutton',
    }, ctx.children);
  }
}
</script>

<style scoped lang="postcss">
@import "../../styles";

.ui-radiobutton {
  @apply --buttonGhostTemplate;
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    fill: inherit;
  }
  &.-active {
    @apply --buttonSecondary;
    fill: white;
  }
}
</style>
